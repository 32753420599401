<template>
  <div class="card card-custom card-stretch gutter-b">
    <div class="card-header border-0 pt-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label font-weight-bolder text-dark"> Stats </span>
        <span class="text-muted mt-3 font-weight-bold font-size-sm"> </span>
      </h3>
      <DatePicker
        :clearable="false"
        style="width: 250px"
        :getPeriod="(data) => getPeriod(data)"
        :default_range="{
          date_from: firstDateOfMonth,
          date_to: new Date(),
        }"
      />
    </div>
    <div class="card-body pt-3">
      <div>
        <LoaderCustom v-if="loading_stats" />
        <chart-line
          v-if="!loading_stats && stats.length"
          :height="300"
          :chartdata="dailyData"
          :options="{
            responsive: true,
            maintainAspectRatio: false,
            scales: {
              yAxes: [
                {
                  ticks: {
                    beginAtZero: true,
                  },
                },
              ],
            },
          }"
        >
        </chart-line>
        <div
          v-else-if="!loading_stats"
          class="card-label font-weight-bolder text-dark text-center mb-3"
        >
          No data
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import moment from "moment";
import * as TYPES from "@/core/services/store/modules/domains/types";
import {
  renderDate,
  DATE_FORMAT,
  requestStatus,
  buildFilterString,
} from "@/core/services/services";

import DatePicker from "../../../../view/content/DatePicker.vue";
import LoaderCustom from "../../../../view/content/LoaderCustom.vue";

import ChartLine from "@/view/content/ChartLine";

export default {
  name: "DashboardStats",
  components: { ChartLine, DatePicker, LoaderCustom },
  data: () => ({
    dateFilter: {
      date_from: "",
      date_to: "",
    },
    firstDateOfMonth: null,
    loading_stats: false,
    show: false,
    dailyData: {
      labels: [],
      datasets: [
        {
          label: "Visits",
          backgroundColor: "rgba(113, 106, 202, 0.5)",
          data: [],
        },
      ],
    },
  }),
  computed: {
    ...mapState({
      stats: (state) => state.domains.stats,
      status: (state) => state.domains.status,
    }),
    renderDate: () => renderDate,
  },
  methods: {
    ...mapActions({
      loadStatsAction: `domains/${TYPES.GET_DOMAIN_STATS}`,
    }),
    getPeriod(data) {
      this.dateFilter = data;
      this.loadStatsAction(
        `${buildFilterString({ ...this.dateFilter })}&ordering=-date`
      );
    },
  },
  watch: {
    stats(newValue) {
      this.dailyData.labels = [];
      this.dailyData.datasets[0].data = [];
      [...newValue].reverse().forEach((item) => {
        this.dailyData.labels.push(moment(item.date).format(DATE_FORMAT));
        this.dailyData.datasets[0].data.push(item.total_visits);
      });
    },
    status(newValue) {
      if (newValue.change_field === "load_stats") {
        this.loading_stats = newValue.load_stats === requestStatus.loading;
      }
    },
  },
  created: function () {
    this.firstDateOfMonth = new Date(
      new Date().setDate(new Date().getDate() - 30)
    );
  },
};
</script>

<style scoped></style>
