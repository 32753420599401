<template>
  <div>
    <div class="row">
      <div class="col-xxl-12">
        <DashboardStats />
      </div>

      <div class="col-xxl-12">
        <DomainsTable
          :list="list"
          :count="count"
          :status="status"
          :title="`<span class='card-label font-weight-bolder text-dark'>New Domains</span>`"
        >
          <div class="row">
            <div class="col">
              <form
                @submit="onSubmitVisitsForm"
                class="filter-item"
                id="kt_subheader_visits_form"
              >
                <div class="form-group">
                  <input
                    v-model.trim="visits"
                    type="number"
                    class="form-control"
                    placeholder="Visits"
                    @input="onChangeVisits"
                    id="visits"
                  />
                </div>
              </form>
            </div>
          </div>
        </DomainsTable>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";

import {
  buildFilterString,
  requestStatus,
  debounce,
} from "@/core/services/services";
import * as TYPES from "@/core/services/store/modules/domains/types";
import * as PAGE_TYPES from "@/core/services/store/modules/page/types";

import DashboardStats from "./components/DashboardStats";
import DomainsTable from "@/view/pages/Domains/components/DomainsTable";

export default {
  name: "Dashboard",
  components: { DashboardStats, DomainsTable },
  data: () => ({
    date_from: null,
    date_to: null,
    visits: "",
  }),
  computed: {
    ...mapState({
      list: (state) => state.domains.list,
      count: (state) => state.domains.count,
      filter: (state) => state.page.filter,
      status: (state) => state.domains.status,
    }),
  },
  methods: {
    ...mapActions({
      loadDataAction: `domains/${TYPES.GET_DOMAINS}`,
    }),
    ...mapMutations({
      setFilter: `page/${PAGE_TYPES.SET_PAGE}`,
      changeFilter: `page/${PAGE_TYPES.CHANGE_FILTER}`,
    }),

    loadData: function (filter) {
      this.loadDataAction(buildFilterString(filter));
    },
    onChangeVisits: function () {
      this.onWaitChangeVisits();
    },
    onSubmitVisitsForm: function (e) {
      e.preventDefault();
      this.changeFilter({ visits: this.visits });
    },
    onWaitChangeVisits: function () {
      this.changeFilter({ visits: this.visits });
    },
  },
  watch: {
    filter(newValue) {
      this.loadData(newValue);
    },
    status(newValue) {
      if (
        (newValue.change_field === "edit" &&
          newValue.edit === requestStatus.success) ||
        (newValue.change_field === "bulk_edit" &&
          newValue.bulk_edit === requestStatus.success)
      ) {
        this.loadData(this.filter);
      }
    },
  },
  created: function () {
    this.onWaitChangeVisits = debounce(this.onWaitChangeVisits, 500);
    this.loadData(this.filter);
  },
};
</script>

<style scoped></style>
